export const formEntries = [
  {
    value: '',
    default: true,
    entryName: 'state',
    placeholder: 'State',
    dropdown: true,
    returnValue: false,
    data: [
      { id: 1, value: 'Kerala' },
      { id: 2, value: 'Goa' },
      { id: 3, value: 'TamilNadu' },
    ],
    validation: { isRequired: true },
  },
  {
    value: '',
    default: true,
    entryName: 'district',
    placeholder: 'District',
    dropdown: true,
    returnValue: false,
    disabled: true,
    data: [
      { id: 1, value: 'Kerala' },
      { id: 2, value: 'Goa' },
      { id: 3, value: 'TamilNadu' },
    ],
    validation: { isRequired: true },
  },
  {
    default: true,
    entryName: 'strategy',
    placeholder: 'Strategy',
    dropdown: true,
    returnValue: true,
    disabled: false,
    data: [
      { id: 1, value: 'Kerala' },
      { id: 2, value: 'Goa' },
      { id: 3, value: 'TamilNadu' },
    ],
    validation: { isRequired: true },
  },
  {
    default: true,
    date: true,
    textInputType: 'default',
    entryName: 'mda_date_completed',
    placeholder: 'MDA Date Completed',
    validation: { isRequired: true },
  },
  // {
  //   default: true,
  //   date: true,
  //   entryName: 'survey_Date_AutoPopulate',
  //   placeholder: 'Survey Date',
  //   textInputType: 'default',
  //   validation: [{ isRequired: true }],
  // },
  {
    value: '',
    default: true,
    date: true,
    entryName: 'date_planned_by_state',
    placeholder: 'Date Planned by State',
    validation: { isRequired: true },
  },
  {
    value: '',
    disabled: true,
    default: true,
    date: true,
    entryName: 'date_proposed_by_nvbdcp',
    placeholder: 'Date Proposed by NVBDCP',
    validation: { isRequired: true },
  },
  // { formTitle: true, hide: false, labelText: 'Coverage', validation: { isRequired: false } },
  // {
  //   default: true,
  //   textField: true,
  //   entryName: 'covered_population',
  //   placeholder: 'Covered Population',
  //   validation: { isRequired: false },
  // },
  // {
  //   default: true,
  //   textField: true,
  //   entryName: 'total_population1',
  //   placeholder: 'Total Population',
  //   textInputType: 'number',
  //   validation: { isRequired: false },
  // },
];
export const DistrictEntries = [
  { formTitle: true, hide: false, labelText: 'Coverage', validation: { isRequired: false } },
  {
    value: '',
    default: true,
    textField: true,
    // disabled: true,
    textInputType: 'number',
    entryName: 'covered_population',
    placeholder: 'Covered Population',
    validation: { isRequired: true },
  },
  // {
  //   isCombined: true,
  //   textField: true,
  //   entryName: 'site_name_S1',
  //   placeholder: 'Site Name S1',
  //   textInputType: 'default',
  //   validation: { isRequired: false },
  // },
  {
    value: '',
    default: true,
    textField: true,
    // disabled: true,
    entryName: 'total_population1',
    placeholder: 'Total Population',
    textInputType: 'number',
    validation: { isRequired: true },
  },
  {
    value: '',
    default: true,
    textField: true,
    // disabled: true,
    textInputType: 'number',
    entryName: 'eligible_population',
    placeholder: 'Eligible Population',
    validation: { isRequired: true },
  },
  { formTitle: true, labelText: 'NBS', validation: { isRequired: false } },
  {
    default: true,
    entryName: 'Test_Type',
    placeholder: 'Test Type',
    dropdown: true,
    returnValue: false,
    data: [],
    validation: { isRequired: true },
  },
  {
    default: true,
    date: true,
    entryName: 'nbs_survey_date',
    placeholder: 'NBS Survey Date',
    validation: { isRequired: true },
  },
  {
    isEmptySpace: true,
    breakpoint: 4,
  },
  // {
  //   value: '',
  //   isCombined: true,
  //   textField: true,
  //   entryName: 'block_name_S1',
  //   placeholder: 'Block Name S1',
  //   textInputType: 'default',
  //   validation: { isRequired: false },
  // },

  {
    value: '',
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'block_name_S1',
    defaultValue: 1,
    sentinal: true,
    onFocusSetDefault: true,
    placeholder: 'Block Name S1',
    data: [],
    validation: { isRequired: false },
  },

  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'site_name_S1',
    placeholder: 'Site Name S1',
    textInputType: 'default',
    defaultValue: 1,
    sentinal: true,
    onFocusSetDefault: true,
    validation: { isRequired: false },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'bse_S1',
    placeholder: 'BSE S1',
    textInputType: 'number',
    defaultValue: 1,
    sentinal: true,
    onFocusSetDefault: true,
    validation: { isRequired: false },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'positive_S1',
    placeholder: 'Positive S1',
    textInputType: 'number',
    defaultValue: 1,
    sentinal: true,
    onFocusSetDefault: true,
    validation: { isRequired: false },
  },

  // {
  //   value: '',
  //   default: true,
  //   textField: true,
  //   entryName: 'mf_rate_S1',
  //   placeholder: 'MF Rate(Auto Populate)',
  //   textInputType: 'default',
  //   validation: { isRequired: true },
  // },
  {
    value: '',
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_S1',
    placeholder: 'MF Rate (Auto Populate)',
    textInputType: 'default',
    validation: [{ isRequired: false }],
  },
  // {
  //   isCombined: true,
  //   textField: true,
  //   entryName: 'block_name_S2',
  //   placeholder: 'Block Name S2',
  //   textInputType: 'default',
  //   validation: { isRequired: false },
  // },
  {
    value: '',
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'block_name_S2',
    placeholder: 'Block Name S2',
    sentinal: true,
    onFocusSetDefault: true,
    defaultValue: 2,
    data: [],
    validation: { isRequired: false },
  },

  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'site_name_S2',
    placeholder: 'Site Name S2',
    textInputType: 'default',
    sentinal: true,
    onFocusSetDefault: true,
    defaultValue: 2,
    validation: { isRequired: false },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'bse_S2',
    placeholder: 'BSE S2',
    textInputType: 'number',
    sentinal: true,
    onFocusSetDefault: true,
    defaultValue: 2,
    validation: { isRequired: false },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'positive_S2',
    placeholder: 'Positive S2',
    textInputType: 'number',
    sentinal: true,
    onFocusSetDefault: true,
    defaultValue: 2,
    validation: { isRequired: false },
  },
  {
    value: '',
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_S2',
    placeholder: 'MF Rate (Auto Populate)',
    textInputType: 'default',
    validation: { isRequired: false },
  },

  // {
  //   isCombined: true,
  //   textField: true,
  //   entryName: 'block_name_R1',
  //   placeholder: 'Block Name R1',
  //   textInputType: 'default',
  //   validation: { isRequired: false },
  // },

  {
    value: '',
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'block_name_R1',
    placeholder: 'Block Name R1',
    random: true,
    onFocusSetDefault: true,
    defaultValue: 1,
    data: [],
    validation: { isRequired: false },
  },

  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'site_name_R1',
    placeholder: 'Site Name R1',
    textInputType: 'default',
    random: true,
    onFocusSetDefault: true,
    defaultValue: 1,
    validation: { isRequired: false },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'bse_R1',
    placeholder: 'BSE R1',
    textInputType: 'number',
    random: true,
    onFocusSetDefault: true,
    defaultValue: 1,
    validation: { isRequired: false },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'positive_R1',
    placeholder: 'Positive R1',
    textInputType: 'number',
    random: true,
    onFocusSetDefault: true,
    defaultValue: 1,
    validation: { isRequired: false },
  },

  {
    value: '',
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R1',
    placeholder: 'MF Rate (Auto Populate)',
    textInputType: 'default',
    validation: { isRequired: false },
  },
  // {
  //   isCombined: true,
  //   textField: true,
  //   entryName: 'block_name_R2',
  //   placeholder: 'Block Name R2',
  //   textInputType: 'default',
  //   validation: { isRequired: false },
  // },
  {
    value: '',
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'block_name_R2',
    placeholder: 'Block Name R2',
    onFocusSetDefault: true,
    random: true,
    defaultValue: 2,
    data: [],
    validation: { isRequired: false },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'site_name_R2',
    placeholder: 'Site Name R2',
    textInputType: 'default',
    onFocusSetDefault: true,
    random: true,
    defaultValue: 2,
    validation: { isRequired: false },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'bse_R2',
    placeholder: 'BSE R2',
    textInputType: 'number',
    onFocusSetDefault: true,
    random: true,
    defaultValue: 2,
    validation: { isRequired: false },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'positive_R2',
    placeholder: 'Positive R2',
    textInputType: 'number',
    onFocusSetDefault: true,
    random: true,
    defaultValue: 2,
    validation: { isRequired: false },
  },

  {
    value: '',
    default: true,
    textField: true,
    disabled: true,
    entryName: 'MF_Rate_R2',
    placeholder: 'MF Rate (Auto Populate)',
    textInputType: 'default',
    validation: { isRequired: false },
  },
];
export const BlockEntries = [
  {
    // value: '',
    default: true,
    textField: true,
    disabled: true,
    textInputType: 'number',
    entryName: 'covered_population',
    placeholder: 'Covered Population (Auto Populate)',
    validation: { isRequired: true },
  },
  {
    // value: '',
    default: true,
    textField: true,
    disabled: true,
    entryName: 'total_population1',
    placeholder: 'Total Population (Auto Populate)',
    textInputType: 'number',
    validation: { isRequired: true },
  },
  { formTitle: true, labelText: 'Coverage/NBS', validation: { isRequired: false } },
  // {
  //   value: '',
  //   default: true,
  //   textField: true,
  //   entryName: 'block_name_B1',
  //   placeholder: 'Block Name1',
  //   textInputType: 'default',
  //   validation: { isRequired: false },
  // },
  {
    value: '',
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'block_name_B1',
    placeholder: 'Block Name1',
    data: [],
    validation: { isRequired: true },
  },

  {
    value: '',
    default: true,
    date: true,
    disabled: true,
    entryName: 'date_completed_B1',
    placeholder: 'Date Completed (Auto Populate)',
    validation: { isRequired: true },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    textInputType: 'number',
    entryName: 'covered_population_B1',
    placeholder: 'Covered Population',
    validation: { isRequired: false },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'total_population_B1',
    placeholder: 'Total Population',
    textInputType: 'number',
    validation: { isRequired: false },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'eligible_Population',
    placeholder: 'Eligible Population',
    textInputType: 'number',
    validation: { isRequired: false },
  },
  {
    value: '',
    default: true,
    entryName: 'test_type_B1',
    placeholder: 'Test Type',
    dropdown: true,
    returnValue: false,
    data: [],
    // validation: { isRequired: true },
  },
  {
    value: '',
    default: true,
    date: true,
    entryName: 'date_of_mf_survey_B1',
    placeholder: 'Date of MF Survey',
    validation: { isRequired: true },
  },
  {
    isEmptySpace: true,
    breakpoint: 4,
  },
  {
    value: '',
    default: true,
    textField: true,
    entryName: 'site_name_S1_B1',
    placeholder: 'Site Name S1',
    textInputType: 'default',
    validation: { isRequired: true },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'bse_S1_B1',
    placeholder: 'BSE S1',
    textInputType: 'number',
    validation: { isRequired: true },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'positive_S1_B1',
    placeholder: 'Positive S1',
    textInputType: 'number',
    validation: { isRequired: true },
  },

  {
    // value: '',
    default: true,
    textField: true,
    disabled: true,
    entryName: 'mf_rate_S1_B1',
    placeholder: 'MF Rate (Auto Populate)',
    textInputType: 'default',
    validation: { isRequired: false },
  },
  {
    value: '',
    default: true,
    textField: true,
    entryName: 'site_name_R1_B1',
    placeholder: 'Site Name R1',
    textInputType: 'default',
    validation: { isRequired: true },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'bse_R1_B1',
    placeholder: 'BSE R1',
    textInputType: 'number',
    validation: { isRequired: true },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'positive_R1_B1',
    placeholder: 'Positive R1',
    textInputType: 'number',
    validation: { isRequired: true },
  },

  {
    // value: '',
    default: true,
    textField: true,
    disabled: true,
    entryName: 'mf_rate_R1_B1',
    placeholder: 'MF Rate (Auto Populate)',
    textInputType: 'default',
    validation: { isRequired: false },
  },
  {
    divider: true,
    fullView: true,
  },
  // {
  //   value: '',
  //   default: true,
  //   textField: true,
  //   entryName: 'block_name_B2',
  //   placeholder: 'Block Name2',
  //   textInputType: 'default',
  //   validation: { isRequired: false },
  // },
  {
    value: '',
    ComboBox: true,
    default: true,
    isCombined: true,
    entryName: 'block_name_B2',
    placeholder: 'Block Name2',
    data: [],
    validation: { isRequired: true },
  },

  {
    value: '',
    default: true,
    date: true,
    disabled: true,
    entryName: 'date_completed_B2',
    placeholder: 'Date Completed (Auto Populate)',
    validation: { isRequired: true },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    textInputType: 'number',
    entryName: 'covered_population_B2',
    placeholder: 'Covered Population',
    validation: { isRequired: false },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'total_population_B2',
    placeholder: 'Total Population',
    textInputType: 'number',
    validation: { isRequired: false },
  },
  {
    default: true,
    entryName: 'test_type_B2',
    placeholder: 'Test Type',
    dropdown: true,
    returnValue: false,
    data: [],
    validation: { isRequired: true },
  },
  {
    value: '',
    default: true,
    date: true,
    entryName: 'date_of_mf_survey_B2',
    placeholder: 'Date of MF Survey',
    validation: { isRequired: true },
  },
  {
    isEmptySpace: true,
    breakpoint: 4,
  },
  {
    value: '',
    default: true,
    textField: true,
    entryName: 'site_name_S1_B2',
    placeholder: 'Site Name S1',
    textInputType: 'default',
    validation: { isRequired: true },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'bse_S1_B2',
    placeholder: 'BSE S1',
    textInputType: 'number',
    validation: { isRequired: true },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'positive_S1_B2',
    placeholder: 'Positive S1',
    textInputType: 'number',
    validation: { isRequired: false },
  },

  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'mf_rate_S1_B2',
    placeholder: 'MF Rate (Auto Populate)',
    textInputType: 'default',
    validation: { isRequired: false },
  },
  {
    value: '',
    default: true,
    textField: true,
    entryName: 'site_name_R1_B2',
    placeholder: 'Site Name R1',
    textInputType: 'default',
    validation: { isRequired: true },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'bse_R1_B2',
    placeholder: 'BSE R1',
    textInputType: 'number',
    validation: { isRequired: true },
  },
  {
    value: '',
    isCombined: true,
    textField: true,
    entryName: 'positive_R1_B2',
    placeholder: 'Positive R1',
    textInputType: 'number',
    validation: { isRequired: true },
  },
  {
    default: true,
    textField: true,
    disabled: true,
    entryName: 'mf_rate_R1_B2',
    placeholder: 'MF Rate (Auto Populate)',
    textInputType: 'default',
    validation: { isRequired: false },
  },
];
export const formDefaultValues = {
  state: '',
  district: '',
  strategy: '',
  mda_date_completed: '',
  date_planned_by_state: '',
  date_proposed_by_nvbdcp: '',
  covered_population: '',
  total_population: '',
};
export const districtDefaultEntries = {
  test_type: '',
  nbs_survey_date: '',
  block_name_S1: '',
  site_name_S1: '',
  bse_S1: '',
  positive_S1: '',
  mf_rate_S1: '',
  block_name_S2: '',
  site_name_S2: '',
  bse_S2: '',
  positive_S2: '',
  mf_rate_S2: '',
  block_name_R1: '',
  site_name_R1: '',
  bse_R1: '',
  positive_R1: '',
  mf_rate_R1: '',
  block_name_R2: '',
  site_name_R2: '',
  bse_R2: '',
  positive_R2: '',
  mf_rate_R2: '',
};

export const blockDefaultEntries = {
  block_name_B1: '',
  date_completed_B1: '',
  covered_population_B1: '',
  total_population_B1: '',
  test_type_B1: '',
  date_of_mf_survey_B1: '',
  site_name_S1_B1: '',
  bse_S1_B1: '',
  positive_S1_B1: '',
  mf_rate_S1_B1: '',
  site_name_R2_B1: '',
  bse_R2_B1: '',
  positive_R2_B1: '',
  mf_rate_R2_B1: '',
  block_name_B2: '',
  date_completed_B2: '',
  covered_population_B2: '',
  total_population_B2: '',
  test_type_B2: '',
  date_of_mf_survey_B2: '',
  site_name_S1_B2: '',
  bse_S1_B2: '',
  positive_S1_B2: '',
  mf_rate_S1_B2: '',
  site_name_R2_B2: '',
  bse_R2_B2: '',
  positive_R2_B2: '',
  mf_rate_R2_B2: '',
};
