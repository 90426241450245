export const thData = ['S.No', 'State', 'District', 'Lymphedema Cases', 'Hydrocele Cases', 'Hydrocele Surgeries','Year','MMDP Kits distributed'];

export const tdData = [
  {
    SNo: 1,
    State: 'Tamil Nadu',
    district: 'Neiveli',
    DatePlanned: '40',
    DateCompleted: '45',
    TestType: '45',  
  },
  {
    SNo: 2,
    State: 'Kerala',
    district: '40',
    DatePlanned: '40',
    DateCompleted: '45',
    TestType: '45',
  },
  {
    SNo: 3,
    State: 'Karnataka',
    district: '40',
    DatePlanned: '40',
    DateCompleted: '45',
    TestType: '45',
  },
  {
    SNo: 4,
    State: 'Andra',
    district: '40',
    DatePlanned: '40',
    DateCompleted: '45',
    TestType: '45',
  },
];
