import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Table from '../../../../components/organisms/Table';
import { thData } from './TableEntries';
import Toaster from '../../../../components/atoms/ToastMessage';
import './DrugTable.css';
import {
  BULK_UPLOAD_DRUG,
  GET_DRUG_AVILABILITY,
  UPDATE_DRUG_AVILABILITY,
  UPLOAD_DRUG_AVILABILITY,
  IDA_STATUS,
} from '../../../../constants/actionType';
import { ERROR } from '../../../../constants/common';


/**
 *
 * @returns {React.ReactElement} - returns the IDA input screen
 */
const DrugTable = (props) => {
  const { onCancel, Activity, jsonData, isJson, setIsJson } = props;
  console.log(isJson,"propsDatas")
  const dispatch = useDispatch();
  const [rowIds, setRowIds] = useState([]);


  const { getDrug } = useSelector((state) => state?.insertDrugDetails);
  console.log("getDrugVal",getDrug);
  

  useEffect(() => {
    dispatch({ type: GET_DRUG_AVILABILITY });
  }, [dispatch]);

  const onSendUpdatedValue = React.useCallback(
    async (key, rowData) => {
      if (jsonData?.length > 0) {
        const arr = jsonData.map((item) => ({
          state: item?.state,
          district: item?.district,
          drug_available_date_DEC: moment(new Date((item?.DEC - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
          drug_available_date_ALB: moment(new Date((item?.ALB - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
          drug_available_date_IVR: moment(new Date((item?.IVR - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD'),
        }));

        dispatch({ type: UPLOAD_DRUG_AVILABILITY, payload: arr });
      } else {
        await dispatch({ type: UPDATE_DRUG_AVILABILITY, payload: rowData });
        setTimeout(() => {
          dispatch({ type: GET_DRUG_AVILABILITY });
        }, 2000);
      }
      setIsJson(false);
    },
    [dispatch, jsonData, setIsJson],
  );
  const [toaster, setToaster] = useState(false);
  console.log(toaster,"toastertoaster");
  
  const [severity, setSeverity] = useState('error');
  const errorMessage = "There is a format error in the uploaded Excel file"; 
  useEffect(() => {
    if (getDrug?.length) {
      const tmpIdsArr = [];
      getDrug.map((value, index) => {
        tmpIdsArr.push(value.drug_availability_id);
        console.log("dragValue",value);
        if (isJson  ) {
          if(value?.district && value?.state === 'undefined'){
            setToaster(true);
            setSeverity('error');
          }
          // dispatch({
          //   type: IDA_STATUS,
          //   payload: { type: ERROR, message: errorMessage },
          // });
        }
        return null; 
      });
      setRowIds(tmpIdsArr);
    }
  }, [dispatch, getDrug, isJson]);

  const closeToaster = () => {
    setToaster(false);
  };

  return (
    <div>
      {toaster && <Toaster open={toaster} severity={severity} message={errorMessage} close={closeToaster} />}
      {/* <Table
        header={thData}
        rows={isJson ? jsonData : getDrug}
        // rows={isJson ? jsonData : ""}
        slideData
        inputTable
        onSendUpdatedData={(key, rowData) => onSendUpdatedValue(key, rowData)}
        hideKeyFields={['block', 'certification_completed', 'drug_availability_id', 'district_id', 'state_id']}
        showSno
        getById="drug_availability_id"
        onCancel={onCancel}
        Activity={Activity}
        uniqueKey="drug_availability_id"
        IsExcelData={isJson}
        screen="input"
      /> */}
      <Table
        header={thData}
        rows={isJson ? jsonData.map(item => ({
          ...item,
          drug_available_date_IVR: item.drug_available_date_IVR || '',
        })) : getDrug.map(item => ({
          ...item,
          drug_available_date_IVR: item.drug_available_date_IVR || '',
        }))}
        slideData
        inputTable
        onSendUpdatedData={(key, rowData) => onSendUpdatedValue(key, rowData)}
        hideKeyFields={['block', 'certification_completed', 'drug_availability_id', 'district_id', 'state_id']}
        showSno
        getById="drug_availability_id"
        onCancel={onCancel}
        Activity={Activity}
        uniqueKey="drug_availability_id"
        IsExcelData={isJson}
        screen="input"
      />

    </div>
  );
};

DrugTable.propTypes = {
  onCancel: PropTypes.func,
  Activity: PropTypes.string,
};

DrugTable.defaultProps = {
  onCancel: () => null,
  Activity: '',
};

export default DrugTable;
