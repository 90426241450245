import axios from 'axios';
import BaseUrl from '../../config/config';
import { getLymphedemaHydrocele, createLymphedemaHydrocele, updateLymphedemaHydrocele, bulkInsertLymphedemaHydrocele } from '../../utils/api';

const userAurhId = localStorage.getItem('UserLoginId');

/**
 * @name getLymphedemaRequest function which make api request to get Demographics.
 * @returns {*} returns axios response data
 */
export const getLymphedemaRequest = () => {
    return axios.post(`${BaseUrl}/${getLymphedemaHydrocele}`);
};
  
/**
 * @param {object} data payload data
 * @name updateLymphedemaRequest function which make api request to update Demographics.
 * @returns {*} returns axios response data
 */
export const updateLymphedemaRequest = (data) => {
    console.log(data,"updateLymphedemaRequest")
    return axios({
        method: 'put',
        url: `${BaseUrl}/${updateLymphedemaHydrocele}`,
        data: {
            updated_by: userAurhId && userAurhId,
            inputScreen: data,
        },
    });
};

/**
 * @param {object} data payload data
 * @name insertLymphedemaRequest function which make api request to add  Demographics.
 * @returns {*} returns axios response data
 */
export const insertLymphedemaRequest = (data) => {
    return axios({
        method: 'post',
        url: `${BaseUrl}/${createLymphedemaHydrocele}`,
        data: {
            user_id:1,
            state_id: data.state,
            district_id: data.district,
            lymphedema_cases: data.lymphedema_cases,
            hydrocele_case: data.hydrocele_case,
            hydrocele_surgeries: data.hydrocele_surgeries,
            year:data.year,
            mmdp_kits_distributed:data.mmdp_kits_distributed
        },
    });
};   
/**
 *  
 * @param  {object} data payload data
 * @returns {*} returns axios response data
 */
export const bulkUploadLymphedemaRequest = (data) => {
    // const formData = new FormData();
    // formData.append('file_upload', data);
    // formData.set('created_by', 1);   
    return axios({
        method: 'post',
        url: `${BaseUrl}/${bulkInsertLymphedemaHydrocele}`,
        data: {
            bulkInsertLymphedemaHydrocele: data,
            created_by: userAurhId && userAurhId,
        },
    });
};
